<template>
  <div class="CardMgrForm-wrapper">
    <form-panel
      ref="formPanel"
      v-bind="submitConfig"
      :form="form"
      :submitBefore="submitBefore"
      :footerShow="backData.cardStatus !== 2"
      @update="update"
    >
      <col2-detail v-if="backData.cardStatus !== 2">
        <col2-block>
          <el-form-item label="卡片名称" :rules="[{ required: true, message: '请输入卡片名称', trigger: 'blur' }]" prop="title">
            <v-input v-model="form.title" placeholder="标题字数最多30个字" :width="width" :maxlength="30"/>
          </el-form-item>
          <el-form-item label="发布项目" :rules="[{ required: true, message: '请输入发布项目', trigger: 'change', type: 'array' }]" prop="communityIds">
            <chosenListPanel v-if="!id" :list.sync="form.communityIds" @select="toSelectCommunity"></chosenListPanel>
            <div v-else>{{backData.communityName}}</div>
          </el-form-item>
          <el-form-item label="跳转类型" required>
            <checkbox-plus :options="skipTypeOps" :value.sync="form.skipType"/>
          </el-form-item>
          <el-form-item v-if="form.skipType === 3" label="封面图片" :rules="[{ required: true, message: '请上传图片', trigger: 'change' }]" prop="picture">
            <v-uploader :action="uploadURL" :imgUrls.sync="form.picture" operate="none">
              <template #tip>
                <span>注：图片大小710x300显示最佳</span>
              </template>
            </v-uploader>
          </el-form-item>
          <el-form-item v-if="form.skipType === 3" label="外部链接" :rules="[{ required: true, validator: urlValidator, trigger: 'blur' }]" prop="skipLink">
            <v-input v-model="form.skipLink" placeholder="请输入外部链接" :width="500" />
            <div>注：请填写带{{protocol}}//的URL地址</div>
          </el-form-item>
          <el-form-item v-if="form.skipType === 4" label="卡片链接" :rules="[{ required: true, validator: urlValidator, trigger: 'blur' }]" prop="cardLink">
            <v-input v-model="form.cardLink" placeholder="请输入卡片链接" @blur="changeIframeUrl" :width="500" />
            &nbsp;&nbsp;&nbsp;
            <v-checkbox class="inlineB" v-model="form.isVideo" type="default" label="视频卡片" ></v-checkbox>
            <div>注：请填写带{{protocol}}//的URL地址</div>
          </el-form-item>
          <el-form-item label="实时预览">
            <div class="outB" v-if="form.skipType === 4"><iframe width="332" height="132" :src="iframeUrl" frameborder="no"></iframe></div>
            <div class="outB" v-else><img v-if="form.picture" :src="form.picture" width="100%" height="100%"></div>
          </el-form-item>
          <el-form-item label="显示位置">
            <v-input-number v-model="form.sort" placeholder="请输入数量" controls controlsPosition="right" :width="width" />
            <br>若不填写排序，则随着发布时间排序
          </el-form-item>

          <el-form-item label="上线时间" :rules="[{ required: true, message: '请输入红包有效期', trigger: 'blur' }]" prop="stayBeginTime">
            <v-datepicker :minuteStep="30" v-model="form.stayBeginTime" type="datetime" :minDate="new Date()" :maxDate="form.stayEndTime"/>
            &nbsp;&nbsp;&nbsp;注：请设置半点或整点的上下架时间
          </el-form-item>
          <el-form-item label="下线时间" :rules="[{ required: true, message: '请输入红包有效期', trigger: 'blur' }]" prop="stayEndTime">
            <v-datepicker :minuteStep="30" v-model="form.stayEndTime" type="datetime" :minDate="form.stayBeginTime || new Date()" />
          </el-form-item>
        </col2-block>
        <col2-block v-if="id" title="操作信息">
          <el-form-item label="关联项目" :rules="[{ required: true, message: '请输入关联项目', trigger: 'change', type: 'array' }]" prop="communityIds">
            <chosenListPanel :list.sync="form.communityIds" @select="toSelectCommunity"></chosenListPanel>
          </el-form-item>
          <el-form-item label="状态" :rules="[{ required: true, message: '请选择状态', trigger: 'change' }]" prop="cardStatus">
            <v-select v-model="form.cardStatus" :options="cardStatusOps" :width="width" />
          </el-form-item>
        </col2-block>
      </col2-detail>
      <col2-detail v-if="backData.cardStatus === 2">
        <col2-item :span="24" label="卡片名称" :text="backData.title" />
        <col2-item :span="24" label="发布项目" :text="backData.communityName" />
        <col2-item :span="24" label="跳转类型" :text="`${backData.skipType === 3 ? '至外部链接' : '自定义'}`" />
        <col2-item v-if="backData.skipType === 3" :span="24" label="封面图片">
          <v-uploader :action="uploadURL" :imgUrls.sync="backData.picture" operate="none">
            <template #tip>
              <span>注：图片大小710x300显示最佳</span>
            </template>
          </v-uploader>
        </col2-item>
        <col2-item :span="24" :label="`${backData.skipType === 3 ? '外部链接' : '卡片链接'}`" :text="backData.skipLink" />
        <col2-item :span="24" label="实时预览">
          <div class="outB" v-if="form.skipType === 4"><iframe width="332" height="132" :src="backData.skipLink" frameborder="no"></iframe></div>
          <div class="outB" v-else><img v-if="backData.picture" :src="backData.picture" width="100%" height="100%"></div>
        </col2-item>
        <col2-item :span="24" label="显示位置" :text="`${backData.sort || 0}`" />
        <col2-item :span="24" label="上线时间" :text="backData.stayBeginTime" />
        <col2-item :span="24" label="下线时间" :text="backData.stayEndTime" />
      </col2-detail>
    </form-panel>
    <!-- 发布项目 multi-select begin -->
      <multi-select
        :isShow.sync="communityMultiShow"
        :searchParams="communitySearchParams"
        v-bind="communityMultiParams"
        :extraParams="grantExtraParams"
        :backFill.sync="form.communityIds">
          <template #searchSlot>
            <v-input label="项目名称" v-model="communitySearchParams.communityName"></v-input>
            <!--此处所属区域只需要选择租户-->
            <v-select v-if="false" label="区域类型" v-model="communitySearchParams.searchRegionType" :options="regionTypeOps"></v-select>
            <v-select2 v-if="!id" label="所属区域" v-model="communitySearchParams.regionId" v-bind="regionParams"></v-select2>
            <v-input v-if="!id" label="所在省" v-model="communitySearchParams.province"></v-input>
            <v-input v-if="!id" label="所在城市" v-model="communitySearchParams.city"></v-input>
            <v-input v-if="!id" label="所在区" v-model="communitySearchParams.area"></v-input>
            <!-- <v-select2 v-if="!id" label="运营项目组" v-model="communitySearchParams.operateRegionId" v-bind="operateRegionParams"></v-select2> -->
            <v-select v-if="!id" label="项目阶段状态" v-model="communitySearchParams.communityStage" :options="communityStageOps"></v-select>
            <v-select v-if="!id" label="项目类型" v-model="communitySearchParams.communityType" :options="communityTypeOps"></v-select>
            <v-select v-if="!id" label="项目状态" v-model="communitySearchParams.communityStatus" :options="communityStatusOps"></v-select>
          </template>
      </multi-select>
      <!-- 发布项目 项目 multi-select end -->
  </div>
</template>
<script>
import {
  updataURL,
  uploadURL,
  getCommunityListURL,
  getCommunityIdListURL,
  getOperateRegionsURL,
  getProjectTypesURL,
  getRoomTypesURL
} from './api'
import {
  communityStatusMap,
  setCommunityStatusOps,
  regionTypeOps,
  setCardStatusOps
} from './map'
import {
  communityParams, regionParams
} from 'common/select2Params'
import { vUploader, vCheckbox } from 'components/control'
import { Col2Detail, Col2Block, Col2Item, MultiSelect, ChosenListPanel, CheckboxPlus } from 'components/bussiness'

export default {
  name: 'CardMgrForm',
  components: {
    vUploader,
    Col2Detail,
    Col2Block,
    Col2Item,
    MultiSelect,
    ChosenListPanel,
    CheckboxPlus,
    vCheckbox
  },
  data () {
    return {
      width: 182,
      id: this.$route.query.id,
      uploadURL: uploadURL,
      protocol: location.protocol,
      cardStatusOps: setCardStatusOps(),
      form: {
        cardType: 50,
        title: '',
        skipType: 4,
        communityIds: [],
        sort: undefined,
        picture: '',
        cardLink: '',
        skipLink: '',
        stayBeginTime: undefined,
        stayEndTime: undefined,
        isVideo: false,
        cardStatus: undefined
      },
      skipTypeOps: [{
        checked: true,
        label: '自定义卡片',
        value: 4
      }, {
        checked: false,
        label: '至外部链接',
        value: 3
      }],
      submitConfig: {
        queryUrl: `${updataURL}/${this.$route.query.id}`,
        submitUrl: this.$route.query.id ? `${updataURL}/` : updataURL,
        submitMethod: this.$route.query.id ? 'put' : 'post'
      },
      iframeUrl: '',
      // 项目 multi-select begin
      communityStatusOps: setCommunityStatusOps(1),
      regionTypeOps,
      communityParams,
      regionParams,
      communityStageOps: [],
      communityTypeOps: [],
      communityMultiShow: false,
      communitySearchParams: {
        communityName: '',
        searchRegionType: undefined,
        regionId: '',
        province: '',
        city: '',
        area: '',
        operateRegionId: '',
        communityStage: undefined,
        communityType: undefined,
        communityStatus: undefined
      },
      operateRegionParams: {
        searchUrl: getOperateRegionsURL,
        request: {
          text: 'regionName',
          value: 'operateRegionId'
        }
      },
      communityMultiParams: {
        searchUrl: this.$route.query.id ? getCommunityIdListURL : getCommunityListURL,
        headers: this.$route.query.id ? [
          {
            prop: 'name',
            label: '项目名称'
          }
        ] : [
          {
            prop: 'communityName',
            label: '项目名称'
          },
          {
            prop: 'communityStatusText',
            label: '项目状态'
          },
          {
            prop: 'communityStage',
            label: '项目阶段'
          },
          {
            prop: 'provinceName',
            label: '所在省'
          },
          {
            prop: 'cityName',
            label: '所在市'
          },
          {
            prop: 'countryName',
            label: '所在区'
          },
          {
            prop: 'communityPhone',
            label: '项目电话'
          }
        ],
        responseParams: this.$route.query.id ? {
          id: 'id',
          name: 'name'
        } : {
          id: 'id',
          name: 'communityName'
        },
        responseKey: {
          id: 'id',
          name: 'text'
        },
        handleData (data) {
          data.communityStatusText = communityStatusMap[data.communityStatus]
        }
      },
      grantExtraParams: {},
      // 项目 multi-select end
      backData: {}
    }
  },
  mounted () {
    const { id } = this.$route.query
    if (id !== undefined) {
      this.$setBreadcrumb('编辑')
      this.$refs.formPanel.getData({ id })
    } else {
      this.$setBreadcrumb('新增')
      this.getProjectTypes()
      this.getRoomTypes()
    }
  },
  methods: {
    getProjectTypes () {
      this.$axios.get(getProjectTypesURL).then(res => {
        if (res.status === '100') {
          let { data } = res
          let ops = data.map((val) => {
            return {
              text: val.value,
              value: val.id
            }
          })
          this.communityStageOps = [{
            text: '全部',
            value: undefined
          },
          ...ops]
        }
      })
    },
    getRoomTypes () {
      this.$axios.get(getRoomTypesURL).then(res => {
        if (res.status === '100') {
          let { data } = res
          let ops = data.map((val) => {
            return {
              text: val.value,
              value: val.id
            }
          })
          this.communityTypeOps = [{
            text: '全部',
            value: undefined
          },
          ...ops]
        }
      })
    },
    urlValidator (rule, value, callback) {
      if (value === '') {
        callback(new Error('该链接地址不能为空'))
      } else if (value.indexOf(this.protocol) < 0) {
        callback(new Error('该链地址不正确'))
      } else {
        callback()
      }
    },
    update (data) {
      this.backData = data
      this.form = {
        id: data.id,
        batchId: data.batchId,
        cardType: data.cardType,
        title: data.title,
        skipType: data.skipType,
        communityIds: data.communitys.map(item => {
          return {
            id: item.communityId,
            text: item.communityName
          }
        }),
        sort: data.sort,
        picture: data.picture,
        cardLink: data.cardLink,
        skipLink: data.skipLink,
        stayBeginTime: data.stayBeginTime,
        stayEndTime: data.stayEndTime,
        isVideo: data.isVideo === 1,
        cardStatus: data.cardStatus
      }
      if (data.skipType === 3) {
        this.skipTypeOps = [{
          checked: false,
          label: '自定义卡片',
          value: 4
        }, {
          checked: true,
          label: '至外部链接',
          value: 3
        }]
      }
      this.grantExtraParams = {
        communityIds: data.communitys.map(item => item.communityId).join(',')
      }
    },
    submitBefore (data) {
      console.log(data)
      if (data.skipType !== 3) {
        delete data.picture
      }
      if (data.skipType !== 4) {
        delete data.cardLink
      }
      data.skipLink = data.skipType === 4 ? data.cardLink : data.skipLink
      data.communityIds = data.id ? data.communityIds.map(item => item.id) : JSON.stringify(data.communityIds.map(item => item.id))
      data.sort = data.sort || 0
      data.isVideo = data.isVideo ? 1 : 0
      data.cardStatus = data.id ? data.cardStatus : 1
      return true
    },
    skipTypeChange (index, val) {
      this.form.skipType = val
    },
    toSelectCommunity () {
      this.communityMultiShow = true
    },
    changeIframeUrl () {
      this.iframeUrl = this.form.cardLink
    }
  }
}
</script>
<style lang="scss" scoped>
.inlineB {
  display: inline-block;
}
.outB {
  width: 332px;
  height: 132px;
  border: 1px solid #dedede;
}
</style>
