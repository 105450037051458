var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "CardMgrForm-wrapper" },
    [
      _c(
        "form-panel",
        _vm._b(
          {
            ref: "formPanel",
            attrs: {
              form: _vm.form,
              submitBefore: _vm.submitBefore,
              footerShow: _vm.backData.cardStatus !== 2,
            },
            on: { update: _vm.update },
          },
          "form-panel",
          _vm.submitConfig,
          false
        ),
        [
          _vm.backData.cardStatus !== 2
            ? _c(
                "col2-detail",
                [
                  _c(
                    "col2-block",
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "卡片名称",
                            rules: [
                              {
                                required: true,
                                message: "请输入卡片名称",
                                trigger: "blur",
                              },
                            ],
                            prop: "title",
                          },
                        },
                        [
                          _c("v-input", {
                            attrs: {
                              placeholder: "标题字数最多30个字",
                              width: _vm.width,
                              maxlength: 30,
                            },
                            model: {
                              value: _vm.form.title,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "title", $$v)
                              },
                              expression: "form.title",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "发布项目",
                            rules: [
                              {
                                required: true,
                                message: "请输入发布项目",
                                trigger: "change",
                                type: "array",
                              },
                            ],
                            prop: "communityIds",
                          },
                        },
                        [
                          !_vm.id
                            ? _c("chosenListPanel", {
                                attrs: { list: _vm.form.communityIds },
                                on: {
                                  "update:list": function ($event) {
                                    return _vm.$set(
                                      _vm.form,
                                      "communityIds",
                                      $event
                                    )
                                  },
                                  select: _vm.toSelectCommunity,
                                },
                              })
                            : _c("div", [
                                _vm._v(_vm._s(_vm.backData.communityName)),
                              ]),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "跳转类型", required: "" } },
                        [
                          _c("checkbox-plus", {
                            attrs: {
                              options: _vm.skipTypeOps,
                              value: _vm.form.skipType,
                            },
                            on: {
                              "update:value": function ($event) {
                                return _vm.$set(_vm.form, "skipType", $event)
                              },
                            },
                          }),
                        ],
                        1
                      ),
                      _vm.form.skipType === 3
                        ? _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "封面图片",
                                rules: [
                                  {
                                    required: true,
                                    message: "请上传图片",
                                    trigger: "change",
                                  },
                                ],
                                prop: "picture",
                              },
                            },
                            [
                              _c("v-uploader", {
                                attrs: {
                                  action: _vm.uploadURL,
                                  imgUrls: _vm.form.picture,
                                  operate: "none",
                                },
                                on: {
                                  "update:imgUrls": function ($event) {
                                    return _vm.$set(_vm.form, "picture", $event)
                                  },
                                  "update:img-urls": function ($event) {
                                    return _vm.$set(_vm.form, "picture", $event)
                                  },
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "tip",
                                      fn: function () {
                                        return [
                                          _c("span", [
                                            _vm._v(
                                              "注：图片大小710x300显示最佳"
                                            ),
                                          ]),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ],
                                  null,
                                  false,
                                  3029548290
                                ),
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.form.skipType === 3
                        ? _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "外部链接",
                                rules: [
                                  {
                                    required: true,
                                    validator: _vm.urlValidator,
                                    trigger: "blur",
                                  },
                                ],
                                prop: "skipLink",
                              },
                            },
                            [
                              _c("v-input", {
                                attrs: {
                                  placeholder: "请输入外部链接",
                                  width: 500,
                                },
                                model: {
                                  value: _vm.form.skipLink,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "skipLink", $$v)
                                  },
                                  expression: "form.skipLink",
                                },
                              }),
                              _c("div", [
                                _vm._v(
                                  "注：请填写带" +
                                    _vm._s(_vm.protocol) +
                                    "//的URL地址"
                                ),
                              ]),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.form.skipType === 4
                        ? _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "卡片链接",
                                rules: [
                                  {
                                    required: true,
                                    validator: _vm.urlValidator,
                                    trigger: "blur",
                                  },
                                ],
                                prop: "cardLink",
                              },
                            },
                            [
                              _c("v-input", {
                                attrs: {
                                  placeholder: "请输入卡片链接",
                                  width: 500,
                                },
                                on: { blur: _vm.changeIframeUrl },
                                model: {
                                  value: _vm.form.cardLink,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "cardLink", $$v)
                                  },
                                  expression: "form.cardLink",
                                },
                              }),
                              _vm._v("\n             \n          "),
                              _c("v-checkbox", {
                                staticClass: "inlineB",
                                attrs: { type: "default", label: "视频卡片" },
                                model: {
                                  value: _vm.form.isVideo,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "isVideo", $$v)
                                  },
                                  expression: "form.isVideo",
                                },
                              }),
                              _c("div", [
                                _vm._v(
                                  "注：请填写带" +
                                    _vm._s(_vm.protocol) +
                                    "//的URL地址"
                                ),
                              ]),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c("el-form-item", { attrs: { label: "实时预览" } }, [
                        _vm.form.skipType === 4
                          ? _c("div", { staticClass: "outB" }, [
                              _c("iframe", {
                                attrs: {
                                  width: "332",
                                  height: "132",
                                  src: _vm.iframeUrl,
                                  frameborder: "no",
                                },
                              }),
                            ])
                          : _c("div", { staticClass: "outB" }, [
                              _vm.form.picture
                                ? _c("img", {
                                    attrs: {
                                      src: _vm.form.picture,
                                      width: "100%",
                                      height: "100%",
                                    },
                                  })
                                : _vm._e(),
                            ]),
                      ]),
                      _c(
                        "el-form-item",
                        { attrs: { label: "显示位置" } },
                        [
                          _c("v-input-number", {
                            attrs: {
                              placeholder: "请输入数量",
                              controls: "",
                              controlsPosition: "right",
                              width: _vm.width,
                            },
                            model: {
                              value: _vm.form.sort,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "sort", $$v)
                              },
                              expression: "form.sort",
                            },
                          }),
                          _c("br"),
                          _vm._v("若不填写排序，则随着发布时间排序\n        "),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "上线时间",
                            rules: [
                              {
                                required: true,
                                message: "请输入红包有效期",
                                trigger: "blur",
                              },
                            ],
                            prop: "stayBeginTime",
                          },
                        },
                        [
                          _c("v-datepicker", {
                            attrs: {
                              minuteStep: 30,
                              type: "datetime",
                              minDate: new Date(),
                              maxDate: _vm.form.stayEndTime,
                            },
                            model: {
                              value: _vm.form.stayBeginTime,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "stayBeginTime", $$v)
                              },
                              expression: "form.stayBeginTime",
                            },
                          }),
                          _vm._v(
                            "\n             注：请设置半点或整点的上下架时间\n        "
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "下线时间",
                            rules: [
                              {
                                required: true,
                                message: "请输入红包有效期",
                                trigger: "blur",
                              },
                            ],
                            prop: "stayEndTime",
                          },
                        },
                        [
                          _c("v-datepicker", {
                            attrs: {
                              minuteStep: 30,
                              type: "datetime",
                              minDate: _vm.form.stayBeginTime || new Date(),
                            },
                            model: {
                              value: _vm.form.stayEndTime,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "stayEndTime", $$v)
                              },
                              expression: "form.stayEndTime",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm.id
                    ? _c(
                        "col2-block",
                        { attrs: { title: "操作信息" } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "关联项目",
                                rules: [
                                  {
                                    required: true,
                                    message: "请输入关联项目",
                                    trigger: "change",
                                    type: "array",
                                  },
                                ],
                                prop: "communityIds",
                              },
                            },
                            [
                              _c("chosenListPanel", {
                                attrs: { list: _vm.form.communityIds },
                                on: {
                                  "update:list": function ($event) {
                                    return _vm.$set(
                                      _vm.form,
                                      "communityIds",
                                      $event
                                    )
                                  },
                                  select: _vm.toSelectCommunity,
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "状态",
                                rules: [
                                  {
                                    required: true,
                                    message: "请选择状态",
                                    trigger: "change",
                                  },
                                ],
                                prop: "cardStatus",
                              },
                            },
                            [
                              _c("v-select", {
                                attrs: {
                                  options: _vm.cardStatusOps,
                                  width: _vm.width,
                                },
                                model: {
                                  value: _vm.form.cardStatus,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "cardStatus", $$v)
                                  },
                                  expression: "form.cardStatus",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
          _vm.backData.cardStatus === 2
            ? _c(
                "col2-detail",
                [
                  _c("col2-item", {
                    attrs: {
                      span: 24,
                      label: "卡片名称",
                      text: _vm.backData.title,
                    },
                  }),
                  _c("col2-item", {
                    attrs: {
                      span: 24,
                      label: "发布项目",
                      text: _vm.backData.communityName,
                    },
                  }),
                  _c("col2-item", {
                    attrs: {
                      span: 24,
                      label: "跳转类型",
                      text: `${
                        _vm.backData.skipType === 3 ? "至外部链接" : "自定义"
                      }`,
                    },
                  }),
                  _vm.backData.skipType === 3
                    ? _c(
                        "col2-item",
                        { attrs: { span: 24, label: "封面图片" } },
                        [
                          _c("v-uploader", {
                            attrs: {
                              action: _vm.uploadURL,
                              imgUrls: _vm.backData.picture,
                              operate: "none",
                            },
                            on: {
                              "update:imgUrls": function ($event) {
                                return _vm.$set(_vm.backData, "picture", $event)
                              },
                              "update:img-urls": function ($event) {
                                return _vm.$set(_vm.backData, "picture", $event)
                              },
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "tip",
                                  fn: function () {
                                    return [
                                      _c("span", [
                                        _vm._v("注：图片大小710x300显示最佳"),
                                      ]),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ],
                              null,
                              false,
                              3029548290
                            ),
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c("col2-item", {
                    attrs: {
                      span: 24,
                      label: `${
                        _vm.backData.skipType === 3 ? "外部链接" : "卡片链接"
                      }`,
                      text: _vm.backData.skipLink,
                    },
                  }),
                  _c("col2-item", { attrs: { span: 24, label: "实时预览" } }, [
                    _vm.form.skipType === 4
                      ? _c("div", { staticClass: "outB" }, [
                          _c("iframe", {
                            attrs: {
                              width: "332",
                              height: "132",
                              src: _vm.backData.skipLink,
                              frameborder: "no",
                            },
                          }),
                        ])
                      : _c("div", { staticClass: "outB" }, [
                          _vm.backData.picture
                            ? _c("img", {
                                attrs: {
                                  src: _vm.backData.picture,
                                  width: "100%",
                                  height: "100%",
                                },
                              })
                            : _vm._e(),
                        ]),
                  ]),
                  _c("col2-item", {
                    attrs: {
                      span: 24,
                      label: "显示位置",
                      text: `${_vm.backData.sort || 0}`,
                    },
                  }),
                  _c("col2-item", {
                    attrs: {
                      span: 24,
                      label: "上线时间",
                      text: _vm.backData.stayBeginTime,
                    },
                  }),
                  _c("col2-item", {
                    attrs: {
                      span: 24,
                      label: "下线时间",
                      text: _vm.backData.stayEndTime,
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "multi-select",
        _vm._b(
          {
            attrs: {
              isShow: _vm.communityMultiShow,
              searchParams: _vm.communitySearchParams,
              extraParams: _vm.grantExtraParams,
              backFill: _vm.form.communityIds,
            },
            on: {
              "update:isShow": function ($event) {
                _vm.communityMultiShow = $event
              },
              "update:is-show": function ($event) {
                _vm.communityMultiShow = $event
              },
              "update:backFill": function ($event) {
                return _vm.$set(_vm.form, "communityIds", $event)
              },
              "update:back-fill": function ($event) {
                return _vm.$set(_vm.form, "communityIds", $event)
              },
            },
            scopedSlots: _vm._u([
              {
                key: "searchSlot",
                fn: function () {
                  return [
                    _c("v-input", {
                      attrs: { label: "项目名称" },
                      model: {
                        value: _vm.communitySearchParams.communityName,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.communitySearchParams,
                            "communityName",
                            $$v
                          )
                        },
                        expression: "communitySearchParams.communityName",
                      },
                    }),
                    false
                      ? _c("v-select", {
                          attrs: {
                            label: "区域类型",
                            options: _vm.regionTypeOps,
                          },
                          model: {
                            value: _vm.communitySearchParams.searchRegionType,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.communitySearchParams,
                                "searchRegionType",
                                $$v
                              )
                            },
                            expression:
                              "communitySearchParams.searchRegionType",
                          },
                        })
                      : _vm._e(),
                    !_vm.id
                      ? _c(
                          "v-select2",
                          _vm._b(
                            {
                              attrs: { label: "所属区域" },
                              model: {
                                value: _vm.communitySearchParams.regionId,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.communitySearchParams,
                                    "regionId",
                                    $$v
                                  )
                                },
                                expression: "communitySearchParams.regionId",
                              },
                            },
                            "v-select2",
                            _vm.regionParams,
                            false
                          )
                        )
                      : _vm._e(),
                    !_vm.id
                      ? _c("v-input", {
                          attrs: { label: "所在省" },
                          model: {
                            value: _vm.communitySearchParams.province,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.communitySearchParams,
                                "province",
                                $$v
                              )
                            },
                            expression: "communitySearchParams.province",
                          },
                        })
                      : _vm._e(),
                    !_vm.id
                      ? _c("v-input", {
                          attrs: { label: "所在城市" },
                          model: {
                            value: _vm.communitySearchParams.city,
                            callback: function ($$v) {
                              _vm.$set(_vm.communitySearchParams, "city", $$v)
                            },
                            expression: "communitySearchParams.city",
                          },
                        })
                      : _vm._e(),
                    !_vm.id
                      ? _c("v-input", {
                          attrs: { label: "所在区" },
                          model: {
                            value: _vm.communitySearchParams.area,
                            callback: function ($$v) {
                              _vm.$set(_vm.communitySearchParams, "area", $$v)
                            },
                            expression: "communitySearchParams.area",
                          },
                        })
                      : _vm._e(),
                    !_vm.id
                      ? _c("v-select", {
                          attrs: {
                            label: "项目阶段状态",
                            options: _vm.communityStageOps,
                          },
                          model: {
                            value: _vm.communitySearchParams.communityStage,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.communitySearchParams,
                                "communityStage",
                                $$v
                              )
                            },
                            expression: "communitySearchParams.communityStage",
                          },
                        })
                      : _vm._e(),
                    !_vm.id
                      ? _c("v-select", {
                          attrs: {
                            label: "项目类型",
                            options: _vm.communityTypeOps,
                          },
                          model: {
                            value: _vm.communitySearchParams.communityType,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.communitySearchParams,
                                "communityType",
                                $$v
                              )
                            },
                            expression: "communitySearchParams.communityType",
                          },
                        })
                      : _vm._e(),
                    !_vm.id
                      ? _c("v-select", {
                          attrs: {
                            label: "项目状态",
                            options: _vm.communityStatusOps,
                          },
                          model: {
                            value: _vm.communitySearchParams.communityStatus,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.communitySearchParams,
                                "communityStatus",
                                $$v
                              )
                            },
                            expression: "communitySearchParams.communityStatus",
                          },
                        })
                      : _vm._e(),
                  ]
                },
                proxy: true,
              },
            ]),
          },
          "multi-select",
          _vm.communityMultiParams,
          false
        )
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }