// 获取列表
const getListURL = `${API_CONFIG.butlerBaseURL}card/list/all`
// 导出列表
const exportListURL = `${API_CONFIG.butlerBaseURL}card/estate/export?cardType=50`
// 批量操作
const batchURL = `${API_CONFIG.butlerBaseURL}card/status`

// 新增 编辑 查看详情
const updataURL = `${API_CONFIG.butlerBaseURL}card/info`
// 上传
const uploadURL = `${API_CONFIG.uploadURL}?module=focus`

// 获取项目列表
const getCommunityListURL = `${API_CONFIG.controlBaseURL}community/getCommunityList`
// 获取项目(已选)列表
const getCommunityIdListURL = `${API_CONFIG.butlerBaseURL}communityInfo/communityId/list`
// 获取运营项目组
const getOperateRegionsURL = `${API_CONFIG.baseURL}serverCodewordAction!getOperateRegions.action`
// 获取阶段状态
const getProjectTypesURL = `${API_CONFIG.baseURL}serverCodewordAction!getProjectTypes.action`
// 项目类型
const getRoomTypesURL = `${API_CONFIG.baseURL}serverCodewordAction!getRoomTypes.action`
export {
  getListURL,
  exportListURL,
  batchURL,
  updataURL,
  uploadURL,
  getCommunityListURL,
  getCommunityIdListURL,
  getOperateRegionsURL,
  getProjectTypesURL,
  getRoomTypesURL
}
